import { action, computed, observable } from 'mobx'
import timezones from '@/constants/timezones.json'
import { v4 as uuidv4 } from 'uuid'
import { map, omit, some } from 'lodash'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { asyncAction } from 'mobx-utils'
import { rules } from '@/plugins/rules'
import { appProvider } from '@/app-provider'
import { CommunityModel } from '@/models/community-model'
import { walletStore } from '@/stores/wallet-store'
import { ProfileModel } from '@/models/profile-model'
import { DaoCategoryModel } from '@/models/dao-category-model'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { formatStringNoExtraSpace } from '@/helper/utils'
import { postController } from '@/modules/common/dialogs/post/post-controller'

const SNS_LINKS_DEFAULT = [
  {
    id: '0',
    linkInput: '',
  },
  {
    id: '1',
    linkInput: '',
  },
]

const CLASSIFICATION_DEAFAULT = ['public', 'private']

const MENU_DEFAULT = [
  {
    id: '0',
    menuInput: '',
  },
  {
    id: '1',
    menuInput: '',
  },
]
const CONTRIBUTOR_DEFAULT = [
  {
    id: '0',
    contributorInput: '',
    profile: undefined,
    loading: false,
    error: false,
  },
  {
    id: '1',
    contributorInput: '',
    profile: undefined,
    loading: false,
    error: false,
  },
]
const RULE_DEFAULT = [
  {
    id: '0',
    ruleTitleInput: '',
    ruleDetailInput: '',
  },
  {
    id: '1',
    ruleTitleInput: '',
    ruleDetailInput: '',
  },
]
const FAQ_DEFAULT = [
  {
    id: '0',
    faqTitleInput: '',
    faqDetailInput: '',
  },
  {
    id: '1',
    faqTitleInput: '',
    faqDetailInput: '',
  },
]

export class CreateCommunityViewModel {
  @observable profileImageFile: File | undefined = undefined
  @observable profileImageSource?: string = ''

  @observable communityNameInput?: string = ''
  @observable communityNameInputError = ''

  @observable ownerAccountInput?: string = ''

  @observable representativeLinkInput?: string = ''

  @observable walletAddressInput?: string = ''

  @observable descriptionInput?: string = ''
  @observable descriptionInputError = ''

  @observable snsLinks = SNS_LINKS_DEFAULT as any

  @observable categories: DaoCategoryModel[] = []
  @observable selectedCategories = [] as any
  @observable categoryError = ''
  @observable timeZoneInput?: string = ''
  @observable timeZoneInputError = ''

  @observable menu = MENU_DEFAULT
  @observable rules = RULE_DEFAULT
  @observable contributors = CONTRIBUTOR_DEFAULT as any
  @observable faqs = FAQ_DEFAULT
  @observable isConfirmPolicy = false
  @observable confirmPolicyError = ''
  @observable contributorProfile: ProfileModel[] = []
  @observable searchInput?: string = ''

  classifications = CLASSIFICATION_DEAFAULT
  @observable classification = null as any
  @observable classificationError = ''

  @observable community?: CommunityModel = undefined
  @observable communityId?: string = undefined

  @observable createCommunityLoading = false
  @observable editCommunityLoading = false
  @observable isValidInput = true
  @observable isDuplicatedCommunityName = false

  constructor() {
    this.setDefaultTimeZone()
    this.loadData()
  }

  @asyncAction *loadData() {
    try {
      this.ownerAccountInput = walletStore.userProfile?.email
      this.walletAddressInput = walletStore.userProfile?.walletAddress
      this.categories = yield apiService.daoCategoryHandler.find({})
    } catch (e) {
      console.log('loadData', e)
    }
  }

  @asyncAction *editCommunityLoadData(id) {
    try {
      loadingController.increaseRequest()
      const communityProfile: CommunityModel = yield apiService.daos.fetchDao(id)
      if (!communityProfile) return
      this.community = communityProfile
      this.communityId = id
      this.profileImageSource = communityProfile.avatar?.url
      this.communityNameInput = communityProfile.name
      this.ownerAccountInput = communityProfile.ownerAccount
      this.representativeLinkInput = communityProfile.representativeLink
      this.walletAddressInput = communityProfile.walletAddress
      this.descriptionInput = communityProfile.description
      this.selectedCategories = map(communityProfile.categories, '_id')
      this.timeZoneInput = communityProfile.region
      this.classification = communityProfile.classification
      const snsLinks = communityProfile.snsLink?.list
      if (snsLinks && snsLinks.length) {
        this.snsLinks = snsLinks.map((item, index) => ({
          id: `${index}`,
          linkInput: item,
        }))
        if (this.snsLinks.length === 1) {
          this.snsLinks.push(SNS_LINKS_DEFAULT[1])
        }
      } else this.snsLinks = SNS_LINKS_DEFAULT

      const menu = communityProfile.menu?.list
      if (menu && menu.length) {
        this.menu = menu.map((item) => ({
          id: item.id,
          menuInput: item.title,
        }))
        if (this.menu.length === 1) {
          this.menu.push(MENU_DEFAULT[1])
        }
      } else this.menu = MENU_DEFAULT

      const rules = communityProfile.rule?.list

      if (rules && rules.length) {
        this.rules = rules.map((item, index) => ({
          id: `${index}`,
          ruleTitleInput: item.title,
          ruleDetailInput: item.detail,
        }))
        if (this.rules.length === 1) {
          this.rules.push(RULE_DEFAULT[1])
        }
      } else this.rules = RULE_DEFAULT

      const contributors = communityProfile.contributors

      if (contributors && contributors.length) {
        this.contributors = contributors.map((item, index) => ({
          id: `${index}`,
          contributorInput: '',
          profile: item,
          loading: false,
          error: false,
        }))
        if (this.contributors.length === 1) {
          this.contributors.push(CONTRIBUTOR_DEFAULT[1])
        }
      } else this.contributors = CONTRIBUTOR_DEFAULT

      const faqs = communityProfile.faq?.list
      if (faqs && faqs.length) {
        this.faqs = faqs.map((item, index) => ({
          id: `${index}`,
          faqTitleInput: item.title,
          faqDetailInput: item.detail,
        }))
        if (this.faqs.length === 1) {
          this.faqs.push(FAQ_DEFAULT[1])
        }
      } else this.faqs = FAQ_DEFAULT
    } catch (e) {
      snackController.commonError(e)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @action changeProfileImageInput(file: File | undefined = undefined) {
    this.profileImageFile = file
    if (file) {
      this.profileImageSource = URL.createObjectURL(file)
    } else this.profileImageSource = ''
  }

  @action setDefaultTimeZone() {
    this.timeZoneInputError = ''
    if (this.timeZoneInput) return
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
    this.timeZoneInput = timezones.find((item) => item.toLocaleLowerCase() === timeZone)
  }

  @action changeCommunityNameInput(input) {
    this.communityNameInput = input
    this.communityNameInputError = ''
    this.isValidInput = true
    this.isDuplicatedCommunityName = false
  }

  @action changeRepresentativeLinkInput(input) {
    this.representativeLinkInput = input
    this.isValidInput = true
  }

  @action changeDescriptionInput(input) {
    this.descriptionInput = input
    this.descriptionInputError = ''
    this.isValidInput = true
  }

  @action changeSnsLink(id, input) {
    const index = this.snsLinks.findIndex((item) => item.id === id)
    if (index === -1) return
    this.snsLinks[index].linkInput = input
    this.isValidInput = true
  }

  @action addSnsLink() {
    this.snsLinks.push({
      id: uuidv4(),
      linkInput: '',
    })
    this.isValidInput = true
  }
  @action removeSnsLink(id) {
    if (this.snsLinks.length <= 1) return
    this.snsLinks = this.snsLinks.filter((item) => item.id !== id)
  }

  @action.bound changeSelectedCategories(value) {
    this.categoryError = ''
    this.isValidInput = true
    this.selectedCategories = value
  }

  @action.bound changetimeZoneInput(input) {
    this.timeZoneInputError = ''
    this.timeZoneInput = input
    this.isValidInput = true
  }

  @action addMenu() {
    this.menu.push({
      id: uuidv4(),
      menuInput: '',
    })
  }

  @action removeMenu(id) {
    if (this.menu.length <= 1) return
    this.menu = this.menu.filter((item) => item.id !== id)
  }

  @action changeMenuInput(id, input) {
    const index = this.menu.findIndex((item) => item.id === id)
    if (index === -1) return
    this.menu[index].menuInput = input
  }

  @action addContributor() {
    this.contributors.push({
      id: uuidv4(),
      contributorInput: '',
      profile: undefined,
      loading: false,
      error: false,
    })
  }

  @asyncAction *changeContributorInput(id, input) {
    this.isValidInput = true
    const index = this.contributors.findIndex((item) => item.id === id)
    if (index === -1) return
    if (!input) {
      this.contributors[index].error = false
      this.contributors[index].contributorInput = ''
      return
    }
    this.contributors[index].error = false
    try {
      this.contributors[index].loading = true
      const res = yield apiService.profiles.getUserProfile({ walletAddress: input })
      if (!res) {
        this.contributors[index].error = true
        return
      }
      this.contributors[index].contributorInput = input
      this.contributors[index].profile = res
    } catch {
      this.contributors[index].profile = undefined
      this.contributors[index].error = true
    } finally {
      this.contributors[index].loading = false
    }
  }

  @action removeContributor(id) {
    if (this.contributors.length <= 1) return
    this.contributors = this.contributors.filter((item) => item.id !== id)
  }

  @action removeContributorProfile(id) {
    const index = this.contributors.findIndex((item) => item.id === id)
    if (index === -1) return
    this.contributors[index].contributorInput = ''
    this.contributors[index].profile = undefined
  }

  @action addFaq() {
    this.faqs.push({
      id: uuidv4(),
      faqTitleInput: '',
      faqDetailInput: '',
    })
  }
  @action removeFaq(id) {
    if (this.faqs.length <= 1) return
    this.faqs = this.faqs.filter((item) => item.id !== id)
  }
  @action changeFaqInput(id, type: 'detail' | 'title', input) {
    const index = this.faqs.findIndex((item) => item.id === id)
    if (index === -1) return
    if (type === 'detail') {
      this.faqs[index].faqDetailInput = input
    } else if (type === 'title') {
      this.faqs[index].faqTitleInput = input
    }
  }
  @action addRule() {
    this.rules.push({
      id: uuidv4(),
      ruleTitleInput: '',
      ruleDetailInput: '',
    })
  }
  @action removeRule(id) {
    if (this.rules.length <= 1) return
    this.rules = this.rules.filter((item) => item.id !== id)
  }
  @action changeRuleInput(id, type: 'detail' | 'title', input) {
    const index = this.rules.findIndex((item) => item.id === id)
    if (index === -1) return
    if (type === 'detail') {
      this.rules[index].ruleDetailInput = input
    } else if (type === 'title') {
      this.rules[index].ruleTitleInput = input
    }
  }

  @action toggleConfirmPolicy() {
    this.isConfirmPolicy = !this.isConfirmPolicy
    this.confirmPolicyError = ''
    this.isValidInput = true
  }

  @action changeClassification(value) {
    this.classificationError = ''
    this.classification = value
    this.isValidInput = true
  }

  @action isEmptyString(s?: string) {
    return s == undefined || !s.trim()
  }

  @asyncAction *validInput() {
    let isValid = true
    if (this.isEmptyString(this.descriptionInput)) {
      this.descriptionInputError = 'Invalid'
      isValid = false
    }

    if (!this.selectedCategories?.length) {
      this.categoryError = 'Invalid'
      isValid = false
    }
    if (!this.timeZoneInput) {
      this.timeZoneInputError = 'Invalid'
      isValid = false
    }
    if (!this.isConfirmPolicy) {
      this.confirmPolicyError = 'Invalid'
      isValid = false
    }
    if (!this.classification) {
      this.classificationError = 'Invalid'
      isValid = false
    }

    if (some(this.contributors, (item) => !!item.error || item.loading)) isValid = false

    if (this.isEmptyString(this.communityNameInput)) {
      this.communityNameInputError = 'Invalid'
      isValid = false
    } else {
      this.isDuplicatedCommunityName = yield apiService.daos.checkDuplicateDaoName({
        name: formatStringNoExtraSpace(this.communityNameInput),
        daoId: this.communityId,
      })
      if (this.isDuplicatedCommunityName) {
        isValid = false
        this.communityNameInputError = 'Invalid'
      }
    }

    return isValid
  }

  @action getPayload() {
    const menu = this.menu
      .filter((item) => !this.isEmptyString(item.menuInput))
      .map((item) => ({
        ...item,
        title: item.menuInput,
      }))

    let payload = {
      name: this.communityNameInput,
      ownerAccount: this.ownerAccountInput,
      representativeLink: this.representativeLinkInput ? this.representativeLinkInput : undefined,
      walletAddress: this.walletAddressInput,
      description: this.descriptionInput,
      snsLink: map(
        this.snsLinks.filter((item) => !this.isEmptyString(item.linkInput)),
        'linkInput'
      ),
      categories: this.selectedCategories,
      region: this.timeZoneInput,
      menu,
      classification: this.classification,
    } as any

    if (this.community && this.community.profile?._id === walletStore.userProfile?._id) {
      payload = {
        ...payload,
        contributors: map(
          this.contributors.filter((item) => !!item.profile),
          'profile.id'
        ),
      }
    }

    const rules = this.rules.filter((item) => !this.isEmptyString(item.ruleTitleInput + item.ruleDetailInput))
    const normalizedRules = [] as any
    rules.map((rule) => {
      normalizedRules.push({
        title: rule.ruleTitleInput,
        detail: rule.ruleDetailInput,
      })
    })
    const faqs = this.faqs.filter((item) => !this.isEmptyString(item.faqTitleInput + item.faqDetailInput))
    const normalizedFaqs = [] as any
    faqs.map((rule) => {
      normalizedFaqs.push({
        title: rule.faqTitleInput,
        detail: rule.faqDetailInput,
      })
    })

    payload = {
      ...payload,
      rule: normalizedRules,
      faq: normalizedFaqs,
    }
    return payload
  }

  @asyncAction *createCommunity() {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.createCommunityLoading = true
      this.isValidInput = yield this.validInput()
      if (!this.isValidInput) {
        return
      }
      let payload = this.getPayload()
      if (this.profileImageFile) {
        const medias = yield apiService.daos.upload(this.profileImageFile)
        const mediaId = medias[0].id
        payload = { ...payload, avatar: mediaId }
      }
      const dao = yield apiService.daos.createDao(payload)
      postController.changeOpenCompletedCreateDao(true)
      if (dao && dao._id) appProvider.router.push(`/community-organize/dao-detail/${dao._id}`)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.createCommunityLoading = false
    }
  }

  @asyncAction *editCommunity() {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.editCommunityLoading = true

      this.isValidInput = yield this.validInput()
      if (!this.isValidInput) {
        return
      }
      let payload = this.getPayload()
      payload = { ...payload, daoId: this.communityId }
      if (this.profileImageFile && this.profileImageSource) {
        const medias = yield apiService.daos.upload(this.profileImageFile)
        const mediaId = medias[0].id
        payload = { ...payload, avatar: mediaId }
      } else if (!this.profileImageSource) {
        payload = { ...payload, avatar: null }
      }
      const dao = yield apiService.daos.updateDao(payload)
      snackController.success('Update Dao successfully')
      if (this.isRctDao) appProvider.router.push(`/community-organize/dao-detail/${dao._id}/setting/dao-members`)
      else if (dao && dao._id) {
        appProvider.router.push(`/community-organize/dao-detail/${dao._id}`)
      }
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.editCommunityLoading = false
    }
  }

  @computed get isRctDao() {
    return this.communityId === process.env.VUE_APP_RECRUITMENT_ID
  }
}
